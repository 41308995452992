import * as React from 'react'
import './App.css';
import {
    AppBar,
    Box,
    Card, CardContent,
    Container,
    createTheme, Grid,
    IconButton, Paper,
    Stack,
    ThemeProvider,
    Toolbar,
    Typography
} from "@mui/material";
import {Copyright} from "./Copyright";
import InfoIcon from '@mui/icons-material/Info';
import {CloudCircle} from "@mui/icons-material";
import {blueGrey} from "@mui/material/colors";

const theme = createTheme(
    {
        palette: {
            primary: blueGrey
        }
    }
);

function App() {
    return (

        <ThemeProvider theme={theme}>
            <Box sx={{flexGrow: 1}}>
                <AppBar position={"static"}>
                    <Toolbar>
                        <IconButton>
                            <CloudCircle/>
                        </IconButton>
                        <Typography variant="h6" component="div">
                            Cloud Connected
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Container sx={{paddingTop: 1}}>
                    <Stack spacing={2}>
                        <Paper elevation={2}>
                            <Box sx={{my: 4}}>

                                <Typography variant="h4" align={"center"}>Cloud Connected</Typography>
                                <Box sx={{margin: 1}}>

                                    <Typography variant="body1">We will allow you to connect to any cloud service
                                        fast</Typography>
                                    <Typography variant={"body1"}>Over 10 years of experience in market</Typography>
                                    <Paper sx={{padding: 1}}>
                                        <Typography variant={"h5"} align={"center"}>Cloud Providers Used</Typography>
                                        <Grid container spacing={2} justifyContent={"space-around"}>
                                            <Grid item xs={2}>
                                                <CardContent>
                                                    <Typography gutterBottom variant={"h6"} component={"div"}>
                                                        Google Cloud
                                                    </Typography>
                                                </CardContent>
                                            </Grid>
                                            <Grid item xs={2}> <CardContent>
                                                <Typography gutterBottom variant={"h6"} component={"div"}>
                                                    AWS
                                                </Typography>

                                            </CardContent></Grid>
                                            <Grid item xs={2}>
                                                <CardContent>
                                                    <Typography gutterBottom variant={"h6"} component={"div"}>
                                                        Cloudflare
                                                    </Typography>
                                                </CardContent>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Box>
                            </Box>
                        </Paper>
                        <Paper>
                            <Box sx={{my: 4}}>

                                <iframe
                                    src="https://docs.google.com/forms/d/e/1FAIpQLSdnYrIhEWRyYjehqmGNZv11PCTefr88zRx1knAGFnxcoju2GA/viewform?embedded=true"
                                    width="100%" height="450" frameBorder="0">Loading…
                                </iframe>

                            </Box>
                        </Paper>
                    </Stack>
                </Container>
                <Copyright/>
            </Box>
        </ThemeProvider>
    );
}

export default App;
